// import Accordions from 'components/Accordions';
// export for others scripts to use

const Accordions = () => {
    const accordions = document.querySelectorAll('.accordions');
    accordions.forEach((el) => {
        let heading = el.querySelectorAll(".accordion .accordion-title");
        heading.forEach((ele) => {
            ele.addEventListener("click", (e) => {
                if(ele.classList.contains("is-active")) {
                    e.preventDefault();
                    ele.classList.remove('is-active');
                    ele.nextElementSibling.classList.remove('is-active');
                } else {
                    heading.forEach((elh) => {
                        elh.classList.remove("is-active")
                        elh.nextElementSibling.classList.remove("is-active")
                    });

                    ele.classList.add('is-active');
                    ele.nextElementSibling.classList.add('is-active');
                }
            })
        })
    });
}
export default Accordions;