/*
 * @title App
 * @description Application entry point
 */

import Menu from './components/menu.js';
import Toggle from './components/toggle.js';
import Tabs  from './components/tabs.js';
import Accordions from './components/accordions.js';
import Audio from './components/audio.js';

document.addEventListener('DOMContentLoaded', function() {
    Menu();
    Toggle();
    Tabs();
    Accordions();
    Audio();
}, false);

import './components/fnJQuery.js';