// import Accordions from 'components/Accordions';
// export for others scripts to use

const Audio = () => {
    const players = document.querySelectorAll('.audio-player');
    const allAudio = document.querySelectorAll('audio');

    players.forEach((el) => {
        const btn = el.querySelector(".btn-play");
        const audio = el.querySelector("audio");
        const progressBar = el.querySelector(".duration");

        btn.addEventListener("click", (e) => {
            if(el.classList.contains('--playing')) {
                el.classList.remove('--playing');
                audio.pause();
            } else {
                allAudio.forEach((au) => {
                    au.pause();
                });

                players.forEach((pl) => {
                    pl.classList.remove('--playing');
                });

                el.classList.add('--playing');

                audio.play();
            }
        });

        audio.addEventListener("timeupdate", () => {
            const currentTime = audio.currentTime;
            const duration = audio.duration;
            const progress = (currentTime / duration) * 100;

            progressBar.style.width = `${progress}%`;
        });
    });
}
export default Audio;