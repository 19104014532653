// import Slider from 'components/menu';
// export for others scripts to use

const Toggle = () => {
	// Tabs panel
	const toggles = document.querySelectorAll('[data-toggle]');

	toggles.forEach((el) => {
		let elTarget = document.querySelector(el.dataset.toggle);

		el.addEventListener('click', (e) => {
			e.preventDefault();
			e.stopPropagation();
			el.classList.toggle('is-active');
			elTarget && elTarget.classList.toggle('is-active');
		})

		document.addEventListener('click', (event) =>  {
			if (!event.target.classList.contains('is-active')) return;
			elTarget && elTarget.classList.remove('is-active');
		}, false);

	});

}
export default Toggle;