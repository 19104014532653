const Menu = () => {
	//Menu
	const menu = document.querySelector('.main-header');
	window.addEventListener('scroll', (e) => {
		if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > 50) {
			menu.classList.add('is-active');
		} else {
			menu.classList.remove('is-active');
		}
	});
}
export default Menu;