// import Slider from 'components/menu';
import gsap from 'gsap';
// export for others scripts to use

const Tabs = () => {
    // Tabs panel
    const tabs = document.querySelectorAll('.tabs');
    if( tabs ) {
        tabs.forEach((el) => {
            let current = -1;
            let container = el.querySelector('.tabs-panel');
            let list = el.querySelectorAll('.tabs-nav .tab');
            let items = el.querySelectorAll('.panel');

            fnTab();

            function fnTab() {
                list.forEach((l) => {
                    l.addEventListener('click', (e) => {
                        e.preventDefault();

                        if (l.classList.contains("is-active")) {
                            e.preventDefault();
                            l.closest(".tabs-nav").classList.toggle('is-show');
                        } else
                            l.closest(".tabs-nav").classList.remove('is-show');

                        let countTabItem = list.length;
                        let percentTabItem = 100/countTabItem;

                        if( l.classList.contains('is-active')) return;
                        l.classList.add('is-active');

                        let index = [...l.parentElement.children].indexOf(l);

                        let dir = index > current ? 1 : -1;

                        if( current >= 0 ) {
                            list[current].classList.remove('is-active');
                            gsap.to(items[current], { duration : 0.6, x : (dir * -percentTabItem) +'%', autoAlpha : 0, ease : "cubic.inOut" })
                        }

                        gsap.fromTo(items[index], { x : (dir * percentTabItem) +'%', autoAlpha : 0 }, { duration : 0.6, x : '0%', autoAlpha : 1, ease : "cubic.out", delay : 0.3});

                        current = index;

                        window.dispatchEvent(new Event('resize'))
                    })
                })
            }

            window.addEventListener('resize', () => {
                items[current] && gsap.to(container, { duration: .2, height : items[current].offsetHeight});
            });

            list[0] && list[0].dispatchEvent(new Event('click'));
        })
    }
};
export default Tabs;